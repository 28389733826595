import React  from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Asudoku
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const login = (username, password) => {
    
    axios({
      method: 'get',
      url: 'http://127.0.0.1:5000/login',
      auth: {
        username: username,
        password: password,
      }
    })
    .then(res => {
      let token = res.data.token
      Cookies.set('current_user_token', token, { expires: 90, sameSite: 'lax' })
      history.push("/")
    })
    .catch(err => {
      if (err.response.status === 401) {
        alert('Incorrect username and/or password combination')
      }
      else {
        alert('Unhandled exception')
      }
    });

  }

  const isUserLoggedIn = () => {

    axios({
      method: 'get',
      url: 'http://127.0.0.1:5000/auth/verify',
      headers: {
        'Access-Control-Allow-Headers': 'x-access-token',
        'x-access-token': Cookies.get('current_user_token')
      }
    })
    .then(res => {
      if (res.status === 200) {
        // User is already logged in
        return true;
      }
      return false;
    })
    .catch(err => {});

  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (isUserLoggedIn()) {
        
        alert('User is already logged in')
        history.push("/");

    }
    else {

      login(document.getElementById('username').value, document.getElementById('password').value);
    
    }

  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}