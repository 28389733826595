import React, { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import MaterialTable from 'material-table'
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

export default function AdminPanel() {
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);

  useEffect(() => {
    axios({
        method: 'get',
        url: 'http://127.0.0.1:5000/users',
        headers: {
            'Access-Control-Allow-Headers': 'x-access-token',
            'x-access-token': Cookies.get('current_user_token')
        }
        })
        .then(res => {
            setRows(res.data.users);
        })
        .catch(err => console.log(err));
  }, []);

  const createUser = (username, password) => {

    axios({
        method: 'post',
        url: 'http://127.0.0.1:5000/register',
        data: {
            "username": username,
            "password": password
        },
    })
    .then(res => { alert('User successfully created !') })
    .catch(err => {
        if (err.response.status === 409) {
            alert('Account with specified username already exists.')
        }
        else {
            alert('Unhandled exception')
        }
    });

  };

  const deleteUser = (username) => {

    axios({
        method: 'delete',
        url: 'http://127.0.0.1:5000/user/delete/' + username,
        headers: {
            'Access-Control-Allow-Headers': 'x-access-token',
            'x-access-token': Cookies.get('current_user_token')
        },
    })
    .then(res => { alert('User successfully deleted !') })
    .catch(err => {
        if (err.response.status === 409) {
            alert('Unauthorized operation !')
        }
        else {
            alert('Unhandled exception')
        }
    });

  };

  const promoteUser = (username) => {

    axios({
        method: 'put',
        url: 'http://127.0.0.1:5000/user/promote/' + username,
        headers: {
          'Access-Control-Allow-Headers': 'x-access-token',
          'x-access-token': Cookies.get('current_user_token')
        }
    })
    .then(res => alert('User successfully promoted !'))
    .catch(err => alert(err));

  };

  const demoteUser = (username) => {

    axios({
        method: 'put',
        url: 'http://127.0.0.1:5000/user/demote/' + username,
        headers: {
          'Access-Control-Allow-Headers': 'x-access-token',
          'x-access-token': Cookies.get('current_user_token')
        }
    })
    .then(res => alert('User successfully demoted !'))
    .catch(err => alert(err));

  };

  return (
    <MaterialTable
      icons={tableIcons}
      title="Manage Users"
      columns={[
        { title: 'ID', field: 'id', editable: 'never' },
        { title: 'Username', field: 'username', editable: 'never' },
        { title: 'Public ID', field: 'public_id', editable: 'never' },
        { title: 'Is Admin?', field: 'admin' },
      ]}
      data={ rows }
      editable={{
        onRowAdd: newRow =>
          new Promise((resolve, reject) => {
            setTimeout(() => {

                createUser(newRow.username, "password");

                if (newRow.admin === "true") {
                    promoteUser(newRow.username);
                }

                setRows([...rows, newRow]);
                
                resolve();

            }, 1000)
        }),
        onRowUpdate: (newRow, oldRow) =>
            new Promise((resolve, reject) => {
            setTimeout(() => {
                
                const rowUpdate = [...rows];
                const index = oldRow.tableData.id;
                rowUpdate[index] = newRow;
                
                if (newRow.admin === 'true') {
                    promoteUser(newRow.username);
                    setRows([...rowUpdate]);
                }
                else if (newRow.admin === 'false') {
                    demoteUser(newRow.username);
                    setRows([...rowUpdate]);
                }

                resolve();
            }, 1000)
        }),
        onRowDelete: oldRow =>
          new Promise((resolve, reject) => {
            setTimeout(() => {

                const rowDelete = [...rows];
                const index = oldRow.tableData.id;
                rowDelete.splice(index, 1);
                deleteUser(oldRow.username);
                setRows([...rowDelete]);
                
                resolve()

            }, 1000)
          }),
      }}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
        }),
        sorting: true
      }}
    />
  )

}