import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomePanel from "./home/HomePanel";
import Scoreboard from './scoreboard/Scoreboard';
import AdminPanel from './admin/AdminPanel';
import UserPanel from "./user/UserPanel";
import { Game } from './sudoku/Game';
import { SudokuProvider } from './sudoku/context/SudokuContext';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NoMeetingRoomIcon from '@material-ui/icons/NoMeetingRoom';
import ScoreIcon from '@material-ui/icons/Score';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import axios from 'axios'
import Cookies from 'js-cookie'
import { useHistory } from "react-router-dom";
import './sudoku/Sudoku.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Home() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isLoggedIn, setLoggedInStatus] = React.useState(false);
  const [isAdmin, setAdminStatus] = React.useState(false);
  const [currentSubpage, setCurrentSubpage] = React.useState('home');
  const history = useHistory();
  
  const handleDrawerOpen = () => { setOpen(true); };
  const handleDrawerClose = () => { setOpen(false); };

  const handleClickHome = () => { setCurrentSubpage('home') };
  const handleClickScoreboard = () => { setCurrentSubpage('scoreboard') };
  const handleClickSudoku = () => { setCurrentSubpage('sudoku') };
  const handleClickAdminPanel = () => { setCurrentSubpage('admin') };
  const handleClickUserPanel = () => { setCurrentSubpage('user') };

  const logoutUser = () => {

    axios({
      method: 'post',
      url: 'http://127.0.0.1:5000/logout',
      headers: {
        'Access-Control-Allow-Headers': 'x-access-token',
        'x-access-token': Cookies.get('current_user_token')
      }
    })
    .then(res => {
      if (res.status === 200) {
        setLoggedInStatus(false);
        Cookies.remove('current_user_token');
        setCurrentSubpage('home');
        history.push('/');
      }
    })
    .catch(err => alert(err));

  };

  const isUserLoggedIn = () => {
    
    axios({
      method: 'get',
      url: 'http://127.0.0.1:5000/auth/verify',
      headers: {
        'Access-Control-Allow-Headers': 'x-access-token',
        'x-access-token': Cookies.get('current_user_token')
      }
    })
    .then(res => {
        setLoggedInStatus(true);
    })
    .catch(err => {
      if (err.status === 401) {
        setLoggedInStatus(false);
      }
    });

    return isLoggedIn;

  };

  const isUserAnAdmin = () => {

    axios({
      method: 'get',
      url: 'http://127.0.0.1:5000/admin/verify',
      headers: {
        'Access-Control-Allow-Headers': 'x-access-token',
        'x-access-token': Cookies.get('current_user_token')
      }
    })
    .then(res => {
      setAdminStatus(true);
  })
  .catch(err => {
    if (err.status === 401) {
      setAdminStatus(false);
    }
  });

    return isAdmin;

  };

  const homeMenuItem = (
    <ListItem button component="a" href="/" onClick={handleClickHome}>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
  );

  const logoutMenuItem = (
    <ListItem button onClick={logoutUser}>
      <ListItemIcon>
        <NoMeetingRoomIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  );

  const loginMenuItem = (
    <ListItem button component="a" href="/login">
      <ListItemIcon>
        <MeetingRoomIcon />
      </ListItemIcon>
      <ListItemText primary="Login" />
    </ListItem>
  );

  const userPanelMenuItem = (
    <ListItem button onClick={handleClickUserPanel}>
      <ListItemIcon>
        <AccountBoxIcon />
      </ListItemIcon>
      <ListItemText primary="User Panel" />
    </ListItem>
  );

  const adminPanelMenuItem = (
    <ListItem button onClick={ handleClickAdminPanel }>
      <ListItemIcon>
        <SupervisorAccountIcon />
      </ListItemIcon>
      <ListItemText primary="Admin Panel" />
    </ListItem>
  );

  const subpageItems = () => {

    if (currentSubpage === 'sudoku') {
      return (
        <SudokuProvider>
          <Game />
        </SudokuProvider>
      );
    }
    else if (currentSubpage === 'scoreboard') {
      return (
        <Scoreboard />
      );
    }
    else if (currentSubpage === 'admin') {
      return (
        <AdminPanel />
      );
    }
    else if (currentSubpage === 'home') {
      return (
        <HomePanel />
      );
    }
    else if (currentSubpage === 'user') {
      return (
          <UserPanel />
      );
    }

    return null;

  };

  const menuItems = () => {
    
    let userIsLoggedIn = isUserLoggedIn();
    let userIsAnAdmin = isUserAnAdmin();

    if (userIsLoggedIn) {

      if (userIsAnAdmin) {

        return (
          <div>
            {homeMenuItem}
            {userPanelMenuItem}
            {adminPanelMenuItem}
            {logoutMenuItem}
          </div>
        );

      }

      else {

        return (
          <div>
            {homeMenuItem}
            {userPanelMenuItem}
            {logoutMenuItem}
          </div>
        );

      }

    }

    else {

      return (
        <div>
          {homeMenuItem}
          {loginMenuItem}
        </div>
      );

    }

  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Asudoku
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>

          {menuItems()}
        
        </List>
        <Divider />
        <List>
          <div>

            <ListItem button onClick={handleClickSudoku}>
              <ListItemIcon>
                <SportsEsportsIcon />
              </ListItemIcon>
              <ListItemText primary="Play Sudoku!" />
            </ListItem>
            
            <ListItem button onClick={handleClickScoreboard}>
              <ListItemIcon>
                <ScoreIcon />
              </ListItemIcon>
              <ListItemText primary="Scoreboard" />
            </ListItem>
          
          </div>
        </List>
      </Drawer>
      
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                
                  {subpageItems()}
                
              </Grid>
            </Grid>
        </Container>
      </main>

      

    </div>
  );
}