import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function UserPanel() {
    const classes = useStyles();
    const history = useHistory();

    const handleUpdatePassword = (evt) => {
        evt.preventDefault();

        if (document.getElementById('new-password').value !== document.getElementById('repeated-new-password').value) {
            alert('New Password and Repeated New Password do not match!');
            return null;
        }

        axios({
            method: 'put',
            url: 'http://127.0.0.1:5000/user/change',
            headers: {
                'Access-Control-Allow-Headers': 'x-access-token',
                'x-access-token': Cookies.get('current_user_token')
            },
            data: {
                "old_password": document.getElementById('old-password').value,
                "new_password": document.getElementById('new-password').value
            }
        })
        .then(res => {
            alert('Successfully changed password!');
            history.push('/');
        })
        .catch(err => {
            if (err.response.status === 401) {
                alert('Specified current password is incorrect!');
            }
            else {
                alert(err);
            }
        });

    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ChangeHistoryIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="old-password"
                        label="Current Password"
                        type="password"
                        id="old-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="new-password"
                        label="New Password"
                        type="password"
                        id="new-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="repeated-new-password"
                        label="Repeat New Password"
                        type="password"
                        id="repeated-new-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleUpdatePassword}
                    >
                        UPDATE
                    </Button>
                </form>
            </div>
        </Container>
    );

}