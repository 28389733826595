class Node {

    constructor(column, row) {

        this.column = column;
        this.row = row;

        this.up = this;
        this.down = this;
        this.left = this;
        this.right = this;

    }

}

class ColumnNode extends Node {

    constructor(id) {

        super(null, id);
        super.column = this;
        this.rowCount =  0;

    }

}

export default class DLX {

    constructor() {

        this.root = new ColumnNode(0);

    }

    nodesAreEqual(firstNode, secondNode) {

        return firstNode.column === secondNode.column &&
            firstNode.up === secondNode.up &&
            firstNode.down === secondNode.down &&
            firstNode.left === secondNode.left &&
            firstNode.right === secondNode.right;

    }

    createMatrix(gridString) {

        let root = this.root;
        let cols = [root];

        for (let i = 0; i < 324; ++i) {

            let c = new ColumnNode(i + 1);

            c.right = root;
            c.left = root.left;

            root.left.right = c;
            root.left = c;

            cols.push(c);

        }
        // console.log(cols)

        let rowConstraint = (x, k) => { return 81 + Math.floor(x / 9) * 9 + k; };
        let columnConstraint = (x, k) => { return 162 + (x % 9) * 9 + k; };
        let boxConstraint = (x, k) => { return 243 + Math.floor(x / 27) * 27 + Math.floor((x % 9) / 3) * 9 + k; };
        let rowNumber = (x, k) => { return x * 9 + k; };

        const appendToColumn = (n) => {

            let c = n.column;
            ++c.rowCount;

            n.down = c;
            n.up = c.up;

            c.up.down = n;
            c.up = n;

        };

        const createLinks = (x, k) => {

            let cellNode = new Node(cols[x + 1], rowNumber(x, k));
            let rowNode = new Node(cols[rowConstraint(x, k)], rowNumber(x, k));
            let columnNode = new Node(cols[columnConstraint(x, k)], rowNumber(x, k));
            let boxNode = new Node(cols[boxConstraint(x, k)], rowNumber(x, k));

            cellNode.right = rowNode;
            cellNode.left = boxNode;

            rowNode.right = columnNode;
            rowNode.left = cellNode;

            columnNode.right = boxNode;
            columnNode.left = rowNode;

            boxNode.right = cellNode;
            boxNode.left = columnNode;

            appendToColumn(cellNode);
            appendToColumn(rowNode);
            appendToColumn(columnNode);
            appendToColumn(boxNode);

        };

        for (const [index, element] of gridString.split('').entries()) {

            if (element === '.') {

                for (let k = 0; k < 9; ++k)
                    createLinks(index, k + 1);

            }

            else {

                createLinks(index, element.charCodeAt(0) - 48);

            }

        }

    }

    chooseLeastColumn() {

        let c = null;
        let i = this.root.right;
        let s = Infinity;

        while (!this.nodesAreEqual(i, this.root)) {

            if (i.rowCount < s) {

                c = i;
                s = i.rowCount;

            }

            //console.log('COL:', c)

            i = i.right;

        }
        //console.log('LEAST COL:', c)
        return c;

    }

    cover(column) {

        column.right.left = column.left;
        column.left.right = column.right;
        let i = column.down;

        while (!this.nodesAreEqual(i, column)) {

            let j = i.right;

            while (!this.nodesAreEqual(j, i)) {

                j.down.up = j.up;
                j.up.down = j.down;

                j.column.rowCount -= 1;
                j = j.right;

            }

            i = i.down;

        }

    }

    uncover(column) {

        let i = column.up;

        while (!this.nodesAreEqual(i, column)) {

            let j = i.left;

            while (!this.nodesAreEqual(j, i)) {

                j.down.up = j;
                j.up.down = j;

                j.column.rowCount += 1;

                j = j.left;

            }

            i = i.up;

        }

        column.right.left = column;
        column.left.right = column;

    }

    search(solution) {

        if (this.nodesAreEqual(this.root, this.root.right))  return [solution, true];

        let c = this.chooseLeastColumn();
        this.cover(c);

        let i = c.down;
        let j;

        // console.log(i, c)

        while (!this.nodesAreEqual(i, c)) {

            solution.push(i);
            j = i.right;

            while (!this.nodesAreEqual(j, i)) {

                this.cover(j.column);
                j = j.right;

            }

            let found;
            [solution, found] = this.search(solution);
            if (found)
                return [solution, true];

            i = solution.pop();
            c = i.column;
            j = i.left;

            while (!this.nodesAreEqual(j, i)) {

                this.uncover(j.column);
                j = j.left;

            }

            i = i.down;

        }

        this.uncover(c);

        return [solution, false];

    }

}