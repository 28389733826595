import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function HomePanel() {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <main className={classes.content}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                        <div>

                            <Typography variant="h4" component="h2" gutterBottom>
                                Introduction To The World Of Sudoku
                            </Typography>

                            <Divider />

                            <br />

                            <Typography variant="body1" gutterBottom>
                                Sudoku has become a very ubiquitous game in our world. People play it online, they play it in newspapers, and they play
                                it while they’re bored on an airplane. Basically anywhere someone might have some downtime there’s probably someone playing
                                sudoku. This math-based puzzle has taken the world by storm and the vast majority of people are thrilled about that.
                            </Typography>

                            <br />

                            <Typography variant="body1" gutterBottom>
                                If you’ve played you’ve definitely gotten to the dreaded point where you don’t know how to solve the puzzle. Maybe you erase
                                and start over, or maybe you just give up. No matter what level of Sudoku you’re at, you’ve most likely wondered if there are
                                strategies you can use to improve your game.
                            </Typography>

                            <br />

                            <Typography variant="body1" gutterBottom>
                                Just like with solitaire or mahjong there are strategies out there that you can use to help increase your chances of solving the
                                sudoku puzzle. It just takes some practice and research to figure out what the strategies are and how they work. If you’re hoping
                                to up your game and get some well-deserved pats on the back from yourself you’re in good hands here.
                            </Typography>

                            <br />

                            <Typography variant="body1" gutterBottom>
                                You’re about to get a deep dive into the world of sudoku, the strategies, and the answers to questions you may have wondered at some
                                point. Maybe you’ve asked yourself when sudoku took over the world as a boredom stopper. (It was invented in 1979 by Howard Garns who
                                based it off of similar games dating back to the 18th century in case you’re curious.)
                            </Typography>

                            <br />

                            <Typography variant="body1" gutterBottom>
                                Before you pick up your pencil or open your app, take some time to read about the strategies and tips here. You might just be able to
                                solve a higher level of puzzle by the time you’re done reading. It’s all about knowing how to work the game and enjoying it in the process.
                                No one becomes a sudoku genius overnight but these strategies will definitely help.
                            </Typography>

                            <br />

                        </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    How Is Sudoku Played?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    The objective of Sudoku is to fill a 9x9 grid made of squares (shown above circled in blue) so that each row, each column,
                                    and each full 9x9 square use the numbers 1-9. While solving the sudoku puzzle you can only use each number one time in the
                                    square, column, and row.
                                </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/2.webp" alt="Sudoku board" width="500" height="300" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Columns and rows are not limited by the thicker boundaries of the square (also sometimes called a region.) They run from the
                                    top of the puzzle to the bottom of the puzzle (as pictured in blue above). The same is true of rows. So while you may be focusing
                                    on a square, you’ll also have to remember other squares are affected by your choices.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    You won’t be able to put a 1 in both the top left corner of the grid and the bottom left corner of the grid, for example. That entire
                                    space is a column, and as you already know, columns must not repeat any of the allowed numbers.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Are Sudoku Puzzles And Magic Squares The Same Thing?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    No. In a Magic Square, you also have to take into account the diagonal portion of the puzzle. Sudoku only focuses on the squares,
                                    rows, and columns in the grid. So, while the concepts are similar, they are not the exact same game.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Some people claim Magic Squares are harder because of the added diagonal factor, while others claim Sudoku requires more concentration
                                    and logic. It really is dependent on what you like best, but in the end, they are still not the exact same thing.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Are Sudoku Variations?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    There are a huge amount of variations on the basic 9x9 grid version of Sudoku. You can do puzzles that change the grid size to 6x6 or even 25x25. There are also other variations like Sudoku-X which adds in the factor of diagonals making it even more similar to a Magic Square.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In Sudoku Even-Odd you have shaded squares that must have either an even or an odd number, depending on the rules of that particular puzzle. In Hanidoku you use hexagonal shapes to create a “grid” that almost looks like a beehive.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In all variants the basic rules usually remain the same, they just add on some extra rules to increase the level of challenge for the avid Sudoku player to enjoy something new. There are tons of easily found variations of Sudoku you can try out once you master the basics and understand the strategies talked about as you keep reading.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    How Are Sudoku Puzzles Rated?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Sudoku puzzles come prefilled with numbers in some of the cells. They are ranked by a difficulty level which corresponds with how many numbers come pre-filled in the puzzle. The higher the difficulty the fewer prefilled numbers you will have. The ratings go from easy to inhuman.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    How Does Sudoku Help Your Brain?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Just like any other muscle your brain benefits from a good workout. If you find yourself doing mundane tasks daily Sudoku could be a way to sharpen your mind a little. It forces you to really think and plan. It gives you something to take all of your attention off of stressful days or worries.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Sudoku has also been linked to declines in the potential for dementia to progress and has helped patients with mental health issues regain some normal daily function abilities. How Sudoku can help your brain is really just about keeping your brain going and your mind sharp. Your individual benefits may not be the same as others, but it’s still offering you a constructive way to pass time.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Where Should I Start My Sudoku Puzzle?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Starting your puzzle is sometimes a key part of making sure you solve the whole thing in the end. Because the puzzles are partially filled it can be difficult to make the first move. The best strategy here is to use the process of elimination. Look at what numbers you’d like to use and mark those numbers in the cells with a pencil.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    You can make them small marks that are easily erased but make sure you’re putting any possible number in the cell. Then check to see if they’ve been repeated in any area you plan to place them.
                                </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/3.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If you’re using the process of elimination as your strategy you can see that although placing a 1 in the upper corner is tempting, it’s not a viable move. The number has already been used in the square. It also cannot be used in the column of the square below it because it has been prefilled there too. That leaves you with only one option. The bottom left.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is how the process of elimination strategy works. You’ll have to broaden your view of the puzzle. Don’t fall into the trap of only looking at one square. Always remember columns and rows matter too. It’s all connected. Strategically eliminating possible moves is the best way to start your game.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is Sudoku Crosshatching?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is a technique slightly the opposite of what you just read about. With crosshatching, you start by focusing on one 3x3 square and filling it in with the numbers needed. It’s a way to give you a starting point for your game that doesn’t involve looking at the entire grid.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Crosshatching can be especially useful for brand new Sudoku players who are overwhelmed with an entire grid to fill. The downfall is that you could find yourself going back to correct a lot of errors as the puzzle gets more filled in. This doesn’t mean it’s a bad technique. It’s just something you should be aware of if you choose to start your puzzle this way.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Can Sudoku Be Solved Without Guessing?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Sudoku is not like solitaire where there’s some luck involved. There are definite ways to solve the puzzle. You might be able to guess and get a lucky right answer, but in general yes, sudoku is solved without guessing. Instead of guessing use strategic thoughtful moves.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Use the strategy of “scan and find” instead of getting too stuck on one square on the grid. If you can’t figure out a number scanning and finding a good spot is always the better strategy. Guessing will not improve your game skills or chances of winning.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Are Some Sudoku Strategies?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    There are actually quite a few strategies sudoku lovers use, and which ones are best vary widely based on who you’re talking to. When it comes to sudoku the best strategy is going to be the one that makes the most sense to you. If a strategy just isn’t working there are others to try so don’t give up. Move on to the next strategy or combine several to get the puzzle solved.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    As you read on you’ll be able to see some commonly used strategies that help people solve their puzzles. Some strategies are more difficult to master than others. Some strategies are meant for advanced players while others are great for beginners. It’s all about what’s going to work for you and what makes sense in your mind.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Try not to get stuck on one specific strategy. It’s completely fine to have a favorite strategy to use but remember that sometimes you might need to use something else to solve the puzzle. If you’re finding yourself stuck think back to the other strategies you’re reading about here. Something is bound to help.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The Naked Pairs Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/5.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This strategy is based on looking at the puzzle and deciding which numbers are the only possibility for a specific cell. It’s similar to the process of elimination you would’ve used to start your puzzle. In a naked pair, you know you have 2 possible numbers that will be able to work in the pair, but you have yet to figure out which will go where.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    When you have a naked pair you can use this strategy to remove the idea of using the 2 numbers in any other row, square/region, or column. From there you’ll have to deduce which number makes the most sense based on looking at the row or column it’s in.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If you know the naked pair has either a two or a six as the possible answer, check to see where those numbers are used already. Remember, you can’t repeat any of the digits you’ve used.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Naked pairs do not have to align within the grid. They can be naked pairs and be scattered within the square too.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    No matter where they fall the point of the strategy is that you know there are only 2 possible numbers that can be placed in those cells and that you need to use the process of elimination to find the right one.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This same idea can also be applied to what more advanced players will call naked triplets (or threes) and naked quads. It’s more difficult than a pair because you’ll have 3 or 4 possible numbers which means you’ll have to solve more of the puzzle than you would with a naked pair.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The Hidden Pairs Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/6.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is a great way to open up your grid and get a good feel for where to place numbers. The hidden pair strategy is a way to eliminate clusters of numbers from two cells which leaves you with simple options for the rest of the cells. It’s a building block for more advanced strategies later.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    To use this strategy you’ll want to look at what numbers have been filled in already. Then look through your square, columns, and rows to rule out those numbers as options. When you do that you’ll be left with a simple pair of number options which you can build on to win the game.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In the above example, you can see that the hidden pair appears to have a multitude of options. Its true value is “hidden” by the appearance of several numeric possibilities. But if we apply the rule of looking through columns and rows we can see that the actual value of those cells is limited to being either a 6 or 7. You’ve used the hidden pairs strategy to rule out everything else.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Again, this strategy can be used in triplets or quads but that could take more practice. It’s not as easy to rule things out when you have more numeric choices. It’s best to start practicing this strategy with pairs and work your way up from there.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The Pointing Pairs Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/7.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    When you’re looking at your grid there may be a number that continues to pop up as a possibility within a specific square. If the numbers are aligned in the same column or row they are called a pointing pair. The pointing pair tells you the number must be used in that line and can be ruled out from other possible cells.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In other words, the repeated possibility aligned within a square “points” to where along the rest of the row or column you can eliminate the number. If you know 3 is a repeated possibility and they’re aligned in the same row or column within the square, you can then rule 3 out from any other cell in those rows or columns.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    The 3 has to be placed within the square you’re looking at, it’s just a matter of figuring out which of the two cells the 3 will be used in. This helps you rule out a lot of possibilities and gives you the chance to fill in some rows or columns because you know a 3 can’t go in any of those cells. It’s a more methodical version of searching and eliminating without a plan.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The Intersection Removal Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is another strategy to help eliminate possibilities and make the entire puzzle more easily solved. Are you noticing a theme with these strategies? It’s all about ruling out what can’t go somewhere instead of focusing on what should be put somewhere. Intersection removal is no exception to that line of thinking.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If any number occurs as a possibility two or three times in any one unit (row, square, or column) you can then remove that number from any intersecting other units. The key to using this strategy is to really fully understand what a unit is in the game. Remember that while it’s easy to focus on one square, you’re trying to solve the whole grid.
                                </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/8.webp" alt="Sudoku board" width="500" height="300" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If the pair or triplet of numbers intersects with another row, column or square it can be eliminated as a possibility for that intersecting unit. Another way to methodically use the process of elimination to get to the final result. This one just takes a little more focus on the entire grid than previous strategies mentioned.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The X-Wing Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    For this strategy, you’re going to be looking at parallel rows and columns. You won’t be focusing on squares as much right now. It’s a strategy to remove possible numbers and erase some of those pencil marks you made when you started your game.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Take a look at your rows and see if there are any pencil marks that are exactly the same in two spots. Match up that row with another row that mirrors it. The pencil marks must be exactly the same in the same two spots. You can see an example below to get a better idea.
                                </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/9.webp" alt="Sudoku board" width="500" height="300" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    As you can see, the parallel rows create an X giving this strategy its name. Looking at the example above you can now see that each of these rows has to have a 4 in it. You also know you can only place the 4 in either the slots that are dark blue, or light blue, since doing anything else would cause a repeated number in the row or column.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    After you’ve established your X formation, look at the entire grid again. See if there’s already a repeated number issue somewhere. This will guide you to the right choice to erase and the right cell to place the X formation numeral.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is a strategy that takes a lot of thought but it does work very well. It’s not a strategy for beginners, but if you’re looking to improve your skills it’s a good choice. It will help you develop the skills required to move beyond focusing on just one square or one row or column. It helps you see the bigger picture.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is The Swordfish Strategy?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Remember how the X wing involved 2 possible numbers in two rows? This is the same idea, except now we’re looking at 3 possible numbers in 3 rows or columns. It’s an advanced strategy for sure. If you haven’t mastered the X wing strategy yet, you’ll likely find this strategy frustrating and possibly useless.                                 </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/10.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    While this strategy is not actually useless it is something that’s hard to spot when you’re looking at potential numbers for your cells. In the above image, the red lines show you that there’s a possibility for a 5 in the same slots in each of the red-lined rows.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    The blue lines show you the slots where a 5 matches up and crosses the blue line itself. In short, the blue lines are showing you where you have the possibility of placing a five. If there is not a somewhere that a blue and red line intersect, you can eliminate five as a candidate in that cell.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    The blue lines will not tell you WHICH cell the five goes in, it just shows you what to eliminate. In the image, you can see that with this strategy the cells highlighted in blue are the ones that might have a five. The cells that cannot have a five are highlighted in red.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Again, this is a highly advanced and somewhat complicated strategy to use for solving your puzzle. It’s best left to Sudoku fans that have been playing for a long time and truly understand how grid patterns work. If you’re just starting out and attempt this strategy you will likely be unable to see the patterns and end up not solving that particular puzzle.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Is “Slicing And Slotting?”
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This is a technique you can use when you haven’t found something that’s working for you on the puzzle yet. Maybe you’ve tried other strategies and you’re at an impasse or you just need a fresh perspective. Slicing and slotting breaks up the puzzle into rows of 3 for you to really analyze what’s missing or what’s incorrect.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    You’ll compare the three rows going number by number. Start in numeric order and go up from there. So while you’re looking at your isolated 3 rows look for every blank spot and see what your possibilities (those pencil marks we talked about) are. This is especially effective if you’ve eliminated candidates at this point.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    While scanning your three rows look to see where there are already cells with the number you’re scanning for. If you’re using the number 1 as your first scan, for example, you’ll want to take note of where in those three rows that number already exists. If you find the number you’re scanning for, look to see how it interacts with your still unsolved cells.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    So, if you’re scanning for the number one, and you find a 1 in the set of three rows you’re scanning check your candidates in that square, row, and column. If you have 1 as a candidate in any of those areas you now know you can eliminate it as a possibility. You wouldn’t be able to place a 1 there, because it’s already in the row, column, or square.
                                </Typography>

                                <br />

                                <img src="https://bestofsudoku.com/img/resources/sudoku-strategy/11.webp" alt="Sudoku board" width="500" height="500" />

                                <br />
                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In the image above you can see this more clearly. The red lines are showing you that in these three focused on columns, you cannot place a 2 anywhere there’s a red line. The 2 has been taken and cannot be reused in that row, square, or column. Therefore if you’d had a 2 as a candidate in one of the red line areas of this example, you could eliminate it.                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    This isn’t a guaranteed way to save your game from being unsolved but it is a good way to break things down. Sometimes you just need a micro focus that allows you to really see what’s going on. That’s what “slicing and slotting” can do for you while solving your Sudoku puzzle.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Can You Solve A Sudoku Puzzle Using Math?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    It depends on what you’re thinking of when you say “math.” This is why people who don’t understand the game often ask what the squares need to add up to in order to solve the puzzle. A Sudoku puzzle doesn’t require arithmetic, meaning you don’t have to add or subtract anything.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    For a Sudoku game you really just need 9 symbols, and numbers just happen to be the most popular version. You could just as well use shapes and still present the same type of puzzle with the same rules and the same outcome. In fact, there are Sudoku versions where letters or symbols are used instead of numbers just to change things up.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    However, Sudoku does require mathmatically logical thinking. So when you’re asking if you can use math to solve a puzzle, the answer really is yes. It’s just probably not the kind of math you’re thinking of. Sudoku is solved with logical reasoning heavily based on concepts used in mathematics.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Can A Sudoku Puzzle Have More Than One Solution?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In most cases, there is only one solution for a Sudoku puzzle. If you find that a prefilled puzzle has more than one solution the publisher likely made a mistake, or you’ve completed the puzzle incorrectly. However, if the puzzle is blank there are definitely multiple solutions.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    In the most extreme case where a puzzle has zero prefilled number clues, Wikipedia states there could be up to 6,670,903,752,021,072,936,960 solutions. So, while in general every puzzle only has one solution, if you did want to do a completely blank Sudoku puzzle, yes, there are definitely many solution options.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    Can A Sudoku Puzzle Be Unsolvable?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    While there seems to be some disagreement on this within the Sudoku fan community, most agree that a true Sudoku puzzle is never unsolvable. They state that a real Sudoku puzzle has one and only one solution. If you work on a puzzle that is unsolvable, it is either an error in printing, or not a true Sudoku puzzle. It is another type of game based on a Sudoku type of grid.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    There are websites and publications dedicated to purposely creating unsolvable puzzles, but those are by design. They’re supposed to be a fun brain teaser for true Sudoku fans. The challenge of trying to solve something someone has claimed isn’t solvable is a huge draw for these types of sites.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={11}>
                        <Paper className={classes.paper}>
                            <div>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    What Should I Do If I’m Stuck On A Sudoku Puzzle?
                                </Typography>

                                <Divider />

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If you’re stuck on a puzzle there aren’t many options available. Your best bet is to erase everything and start with a clean slate. If you really wanted to challenge yourself you could start by erasing rows or columns with repeated numbers, but that could lead to more frustration.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    If you start over, attempt a new strategy that you haven’t tried yet. It might be the fresh perspective you need to get the puzzle solved successfully. While every puzzle does have a solution, not every puzzle is solved by every person. Sudoku is a puzzle game that requires skill building. Don’t get too frustrated if you have to start over. It’s all part of the process.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Keep testing strategies you’ve learned about here and find what works for you. Even the best Sudoku solvers don’t use every strategy out there. The puzzle may only have one solution but how you get there is up to you.
                                </Typography>

                                <br />

                                <Typography variant="body1" gutterBottom>
                                    Remember it’s supposed to be a fun brain-teasing type of activity. Once you have some basics down you’ll find you’re ready to tackle higher difficulty ranks and really enjoy your time spent playing.
                                </Typography>

                                <br />

                            </div>
                        </Paper>
                    </Grid>

                </Grid>
            </main>
        </Container>
    );

}