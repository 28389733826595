import DLX from './DLX';

export default class SudokuSolver {

    static solve(gridString) {

        let solver = new DLX();

        solver.createMatrix(gridString);

        let DLXSolution;
        let found;

        [DLXSolution, found] = solver.search([]);

        return [DLXSolution, found];

    }

    static outputSolution(DLXSolution, found) {

        if (!found) { return null; }

        let solution = new Array(81).fill(0);

        for (let i of DLXSolution) {

            let val = i.row % 9;
            if (val === 0) { val = 9; }

            solution[Math.floor((i.row - 1) / 9)] = val;

        }

        let solvedBoard = [];
        for (let i of solution) { solvedBoard.push(i.toString()); }

        return solvedBoard.join('');

    }

}