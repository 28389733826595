import React from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

// Pages

import NotFoundPage from './pages/404'
import RegisterPage from './pages/register'
import LoginPage from './pages/login'
import HomePage from './pages/home'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}
