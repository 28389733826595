import SudokuGenerator from './Generator';
import SudokuSolver from './Solver';

/**
 * Initializes a null array for easier resets in the code.
 */
let nullArray = [ '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0',
                  '0', '0', '0', '0', '0', '0', '0', '0', '0' ];

/**
 * Gets the coordinates of the center cell of the specified box.
 */
function _getBoxCenter(box) {
  // eslint-disable-next-line
  switch(box) {
    case 0: return [1,1];
    case 1: return [1,4];
    case 2: return [1,7];
    case 3: return [4,1];
    case 4: return [4,4];
    case 5: return [4,7];
    case 6: return [7,1];
    case 7: return [7,4];
    case 8: return [7,7];
  }
}

/**
 * Gets the index of cell given:
 * 1. Box
 * 2. Cell
 */
function _getIndexOfCell(box, cell) {
  let [row, column] = _getBoxCenter(box);
  // eslint-disable-next-line
  switch(cell) {
    case 0: {row--; column--; break;}
    case 1: {row--; break;}
    case 2: {row--; column++; break;}
    case 3: {column--; break;}
    case 4: {break;}
    case 5: {column++; break;}
    case 6: {row++; column--; break;}
    case 7: {row++; break;}
    case 8: {row++; column++; break;}
  }
  return row * 9 + column;
}

/**
 * Checks if Cell is available or not (i.e., filled).
 */
function _cellAvailable(tempInitArray, box, value) {
  return tempInitArray[_getIndexOfCell(box, value)] === '0' ? 0 : 1;
}

/**
 * Generates a Unique Sudoku puzzle from a solved Sudoku.
 */
function _generateUniqueSudoku(solvedArray, difficulty, e) {
  let currentDifficulty = difficulty;
  let minimumCells, maximumCells, totalCells, box, cell;

  let tempInitArray = nullArray.slice();
  let boxCounts = [ 0,0,0,
                    0,0,0,
                    0,0,0 ];
  let boxesAvailable = [];
  let cellsAvailable = [];

  if (e)
    currentDifficulty = e.target.value;

  if (currentDifficulty === 'Easy') {
    minimumCells = 3;
    maximumCells = 7;
    totalCells = 61;
  }
  else if (currentDifficulty === 'Medium') {
    minimumCells = 2;
    maximumCells = 6;
    totalCells = 52;
  }
  else if (currentDifficulty === 'Hard') {
    minimumCells = 1;
    maximumCells = 5;
    totalCells = 43;
  }
  else if (currentDifficulty === 'Very Hard') {
    minimumCells = 1;
    maximumCells = 4;
    totalCells = 34;
  }
  else if (currentDifficulty === 'Insane') {
    minimumCells = 1;
    maximumCells = 3;
    totalCells = 25;
  }
  else if (currentDifficulty === 'Inhuman') {
    minimumCells = 0;
    maximumCells = 2;
    totalCells = 17;
  }

  for (let j = 0; j < 9; j++) {
    boxCounts[j] =  _cellAvailable(tempInitArray, j, 0) +
                    _cellAvailable(tempInitArray, j, 1) +
                    _cellAvailable(tempInitArray, j, 2) +
                    _cellAvailable(tempInitArray, j, 3) +
                    _cellAvailable(tempInitArray, j, 4) +
                    _cellAvailable(tempInitArray, j, 5) +
                    _cellAvailable(tempInitArray, j, 6) +
                    _cellAvailable(tempInitArray, j, 7) +
                    _cellAvailable(tempInitArray, j, 8);
  }

  for (let i = 0; i < totalCells; i++) {
    boxesAvailable = [];
    for (let j = 0; j < 9; j++) {
      if (boxCounts[j] < minimumCells) {
        boxesAvailable.push(j);
      }
    }
    if (boxesAvailable) {
      for (let j = 0; j < 9; j++) {
        if (boxCounts[j] < maximumCells) {
          boxesAvailable.push(j);
        }
      }
    }
    box = boxesAvailable[Math.random() * boxesAvailable.length | 0];

    cellsAvailable = [];
    for (let j = 0; j < 9; j++) {
      if ( tempInitArray[_getIndexOfCell(box, j)] === '0') {
        cellsAvailable.push(j);
      }
    }
    cell = cellsAvailable[Math.random() * cellsAvailable.length | 0];

    let index = _getIndexOfCell(box, cell);
    tempInitArray[index] = solvedArray[index]
    boxCounts[box]++;
  }

  return tempInitArray;
}

export const getUniqueSudoku = (difficulty, e) => {
  let temporaryInitArray = nullArray.slice();
  let temporarySolvedArray = nullArray.slice();
  let sudokuGenerator = new SudokuGenerator();

  /**
   *
   */
  let str = sudokuGenerator.generate(difficulty);
  [...str].forEach((value, index) => {
    temporaryInitArray[index] = value === '.'
                        ? '0'
                        : value;
  });

  /**
   * Get the solution from sudoku.js
   */
  let solution;
  let found;
  [solution, found] = SudokuSolver.solve(str);
  str = SudokuSolver.outputSolution(solution, found);
  [...str].forEach((value, index) => {
    temporarySolvedArray[index] = value;
  });

  /**
   * Pass the generated solution and get a unique Sudoku from it!
   */
  temporaryInitArray = _generateUniqueSudoku(temporarySolvedArray, difficulty, e);

  return [temporaryInitArray, temporarySolvedArray];
}
