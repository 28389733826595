import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from 'axios';
import Cookies from 'js-cookie';
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [sudoku, setSudoku] = React.useState(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: 'http://127.0.0.1:5000/scoreboard',
        })
            .then(res => {
                let tempRows = res.data.entries;
                let tempItems = [];
                tempRows.forEach(row => {
                    tempItems.push(
                        <MenuItem value={ row }>SudokuID: {row.sudoku_id} -- Best Time: {row.best_time} -- User: {row.best_time_username}</MenuItem>
                    )
                });
                setItems(tempItems);
            })
            .catch(err => console.log(err));
    }, []);

    const handleChange = (event) => {
        setSudoku(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePlay = () => {

        let id;
        if (sudoku === null) id = 0;
        else id = sudoku.sudoku_id;

        axios({
            method: 'get',
            url: 'http://127.0.0.1:5000/sudoku/' + id.toString(),
            headers: {
                'Access-Control-Allow-Headers': 'x-access-token',
                'x-access-token': Cookies.get('current_user_token')
            }
        })
            .then(res => {
                handleClose();
                props.onLoadGame(res.data.initial_board, res.data.solved_board);
            })
            .catch(err => {
                if (!err.response) { alert(err); return; }
                if (err.response.status === 404) {
                    handleClose();
                    props.onLoadGame(null, null);
                }
            });

    };

    return (
        <>
            <Button fullWidth={ true } variant="contained" onClick={ props.onNewGame }>
                New game
            </Button>
            <br />
            <br />
            <Button fullWidth={ true } variant="contained" onClick={ handleClickOpen }>
                Load game
            </Button>
            <Dialog open={open} onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Load Game</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you wish to solve a specific sudoku puzzle, choose it from the dropdown list and press Play.
                    </DialogContentText>
                    <FormControl variant="outlined" className={ classes.formControl } fullWidth={ true }>
                        <InputLabel id="demo-simple-select-outlined-label">Sudoku</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={ sudoku }
                            onChange={ handleChange }
                            label="Sudoku"
                        >

                            <MenuItem value={ null }>
                                <em>None</em>
                            </MenuItem>
                            { items }

                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ handlePlay } color="primary">
                        Play
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}
